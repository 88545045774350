"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debugPatientLeistungenColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const types_1 = require("@rose/types");
const behandlerStore_1 = require("@/state/behandlerStore");
function debugPatientLeistungenColumnsDefs() {
    return [
        {
            headerName: 'extid',
            field: 'extid',
            sortable: true,
            sort: 'asc',
            width: 80,
        },
        {
            headerName: 'Tag',
            field: 'tag',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            width: 70,
        },
        {
            headerName: 'Nummer',
            field: 'nummer',
            sortable: true,
            width: 80,
        },
        {
            headerName: 'Abrechnungsnummer',
            field: 'abrechnungsnummer',
            sortable: true,
            width: 80,
        },
        {
            headerName: 'Bezeichnung',
            field: 'bezeichnung',
            width: 350,
        },
        {
            headerName: 'Anzahl',
            field: 'anzahl',
            sortable: true,
            width: 40,
        },
        {
            headerName: 'Sitzung',
            field: 'sitzung',
            sortable: true,
            width: 40,
        },
        {
            headerName: 'HKP',
            field: 'hkpid',
            sortable: true,
            width: 60,
        },
        {
            headerName: 'Art',
            field: 'art',
            sortable: true,
            valueGetter: params => params.data && types_1.BemaGozType[params.data.art],
            width: 70,
        },
        {
            headerName: 'GOZ-Art',
            field: 'gozart',
            sortable: true,
            valueGetter: params => params.data && types_1.GozArtType[params.data.gozart],
            width: 80,
        },
        {
            headerName: 'PZ',
            headerTooltip: 'Punktzahl',
            field: 'punktzahl',
            sortable: true,
            width: 50,
        },
        {
            headerName: 'PW',
            headerTooltip: 'Punktwert',
            field: 'punktwert',
            sortable: true,
            width: 60,
        },
        {
            headerName: 'Faktor',
            field: 'faktor',
            sortable: true,
            width: 40,
        },
        {
            headerName: 'Betrag',
            field: 'betrag',
            valueFormatter: common_ui_1.euroAndCentFormatter,
            sortable: true,
            width: 80,
        },
        {
            headerName: 'MwSt-Betrag',
            field: 'mwstbetrag',
            valueFormatter: common_ui_1.euroAndCentFormatter,
            sortable: true,
            width: 80,
        },
        {
            headerName: 'MwSt-Satz',
            field: 'mwstsatz',
            valueFormatter: common_ui_1.percentFormatter,
            sortable: true,
            width: 80,
        },
        {
            headerName: 'Behandler',
            field: 'behandler',
            valueGetter: params => { var _a, _b; return params.data && ((_b = (_a = behandlerStore_1.behandlerStore.getters.map[params.data.behandler]) === null || _a === void 0 ? void 0 : _a.displayText) !== null && _b !== void 0 ? _b : params.data.behandler); },
            sortable: true,
            width: 100,
        },
        {
            headerName: 'Abgerechnet',
            field: 'abgerechnet',
            valueFormatter: common_ui_1.booleanJaNeinFormatter,
            sortable: true,
            width: 40,
        },
        {
            headerName: 'Abrechnungsdatum',
            field: 'abgerechnetam',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            width: 70,
        },
        {
            headerName: 'Rechnung',
            field: 'rechnung',
            sortable: true,
            width: 70,
        },
        {
            headerName: 'erstelltam',
            field: 'erstelltam',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            width: 80,
        },
        {
            headerName: 'geaendertam',
            field: 'geaendertam',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            width: 80,
        },
    ];
}
exports.debugPatientLeistungenColumnsDefs = debugPatientLeistungenColumnsDefs;
