"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var patientenDebugStore_1 = require("@/state/patientenDebugStore");

var common_ui_1 = require("@rose/common-ui");

var leistungen_1 = require("@/components/columns/patienten-debug/leistungen");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      columnDefs: (0, leistungen_1.debugPatientLeistungenColumnsDefs)(),
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {}
    };
  },
  computed: {
    patient: function patient() {
      return patientenDebugStore_1.patientenDebugStore.state.selectedPatient;
    },
    leistungen: function leistungen() {
      return patientenDebugStore_1.patientenDebugStore.state.leistungen;
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    }
  }
});